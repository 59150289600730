export function data() {
  return {
    search: '',
    fields_excel: {
      Veículo: 'veiculo.placa_cavalo',
      Senha: {
        callback: (value) => {
          value.senhas.length > 0 ? value.senhas[0].sequencia : '-'
        },
      },
      'Triado Em': 'triado_em',
      Cliente: 'cliente.business_name',
      'Tempo em pátio': {
        callback: (value) => {
          return `${this.getTempoEmPatio(value)}`
        },
      },
      'Nº Pedido': {
        callback: (value) => {
          return `${value.pedidos_agendados[0].pedido.numero_pedido}`
        },
      },
      'Nº Nota fiscal': {
        callback: (value) => {
          return `${value.pedidos_agendados[0].numero_nfe}`
        },
      },

      Produto: {
        callback: (value) => {
          return `${value.pedidos_agendados[0].pedido.produto.descricao}`
        },
      },
      SubProduto: {
        callback: (value) => {
          return `${value.pedidos_agendados[0].pedido.subProduto.descricao}`
        },
      },
      'Quantidade (KG)': {
        callback: (value) => {
          return `${value.pedidos_agendados[0].pedido.quantidade}`
        },
      },
      Embalagem: {
        callback: (value) => {
          return `${value.pedidos_agendados[0].pedido.embalagem.descricao}`
        },
      },
      Eixos: 'veiculo.modalidade.eixo',
      Motorista: 'motorista.user.name',
      Modalidade: 'veiculo.modalidade.descricao',
    },

    headers: [
      {
        text: 'Ações',
        divider: true,
        sortable: false,
        value: 'acoes',
        class: 'primary white--text',
      },
      {
        text: 'Senha',
        divider: true,
        value: 'senhas',
        class: 'primary white--text',
      },
      {
        text: 'Placa',
        divider: true,
        value: 'veiculo.placa_cavalo',
        class: 'primary white--text',
      },
      {
        text: 'Carreta 1',
        divider: true,
        value: 'veiculo.placa_carreta_1',
        class: 'primary white--text',
      },
      {
        text: 'Carreta 2',
        divider: true,
        value: 'veiculo.placa_carreta_2',
        class: 'primary white--text',
      },
      {
        text: 'Modalidade',
        divider: true,
        value: 'veiculo.modalidade.descricao',
        class: 'primary white--text',
      },
      {
        text: 'Carroceria',
        divider: true,
        value: 'veiculo.carroceria.descricao',
        class: 'primary white--text',
      },
      {
        text: 'Nota fiscal',
        divider: true,
        value: 'pedidos_agendados.numero_nfe',
        class: 'primary white--text',
      },
      {
        text: 'Tipo operação',
        divider: true,
        value: 'tipo_operacao',
        class: 'primary white--text',
      },
      {
        text: 'Quantidade (KG)',
        divider: true,
        value: 'pedidos_agendados.quantidade',
        class: 'primary white--text',
      },
      {
        text: 'Motorista',
        divider: true,
        value: 'motorista.user.name',
        class: 'primary white--text',
      },
      {
        text: 'Telefone',
        divider: true,
        value: 'motorista.user.celular',
        class: 'primary white--text',
      },
      {
        text: 'Sub Produto',
        divider: true,
        value: 'sub_produtos',
        class: 'primary white--text',
      },
      {
        text: 'Embalagem',
        divider: true,
        value: 'embalagem',
        class: 'primary white--text',
      },
      {
        text: 'Triado Em',
        divider: true,
        value: 'triado_em',
        class: 'primary white--text',
      },
      {
        text: 'Data da Cota',
        divider: true,
        value: 'data_cota',
        class: 'primary white--text',
      },
      {
        text: 'Tempo em Patio',
        divider: true,
        value: 'tempoEmPatio',
        class: 'primary white--text',
      },
      {
        text: 'Número do Pedido',
        divider: true,
        value: 'numero_pedido',
        class: 'primary white--text',
      },
      {
        text: 'Data do Pedido',
        divider: true,
        value: 'data_pedido',
        class: 'primary white--text',
      },
      {
        text: 'Fornecedor',
        divider: true,
        value: 'fornecedor.business_name',
        class: 'primary white--text',
      },
      {
        text: 'Cliente',
        divider: true,
        value: 'cliente.business_name',
        class: 'primary white--text',
      },
      {
        text: 'Transportadora',
        divider: true,
        value: 'transportadora.business_name',
        class: 'primary white--text',
      },
      {
        text: 'Destinatario',
        divider: true,
        value: 'destinatario.business_name',
        class: 'primary white--text',
      },
    ],
    selecionados: [],
    quantidade: null,
    terminalDestino: null,
    modalChamarCaminhoes: false,
    showComprovanteLaudo: false,
    itensLaudo: [],
  }
}
