<template>
  <BaseCard>
    <v-dialog v-model="modalChamarCaminhoes" max-width="500px">
      <modal-chamar-caminhoes
        :callback="chamarCaminhoes"
        :terminalDestino.sync="terminalDestino"
        :quantidade="quantidadeSelecionada"
        ref="modal"
      />
    </v-dialog>

    <v-card>
      <validation-observer v-slot="{}">
        <v-card-actions class="justify-end">
          <v-col cols="2">
            <validation-provider
              :rules="{
                required: true,
                min_value: 0,
                max_value: emPatioValido.length,
              }"
              name="quantidade"
              v-slot="{ errors }"
            >
              <v-text-field
                v-if="canUpdate"
                v-model="quantidade"
                label="Quantidade"
                type="number"
                :min="1"
                :max="emPatioValido.length"
                :error-messages="errors"
                @input="selecionarQuantidadeAutomaticamente"
              />
            </validation-provider>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="canUpdate"
              color="primary"
              class="mx-2"
              :disabled="quantidadeSelecionada <= 0"
              @click="showModal"
              >Chamar ({{ quantidadeSelecionada }})</v-btn
            >
          </v-col>
        </v-card-actions>
      </validation-observer>
    </v-card>
    <v-card>
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="orderedEmPatio"
        class="elevation-1"
      >
        <template v-slot:[`header.acoes`]>
          <v-icon v-if="canList" @click="selecionarTodos" class="white--text">
            {{ icon }}
          </v-icon>
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-2 mb-2 menu"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template>
                <v-list-item
                  class="pointer"
                  @click="handleVisualizarComprovanteLaudo(item)"
                  >Laudo de Limpeza</v-list-item
                >
              </template>
            </v-list>
          </v-menu>
          <v-checkbox
            v-if="verificaDataCota(item) && verificaDataPedido(item)"
            :value="item.public_id"
            v-model="selecionados"
          />
        </template>
        <template v-slot:[`item.senhas`]="{ item }">
          {{ item.senhas.length > 0 ? item.senhas[0].sequencia : '-' }}
        </template>
        <template v-slot:[`item.embalagem`]="{ item }">
          {{
            item.pedidos_agendados.length > 0
              ? item.pedidos_agendados[0].pedido.embalagem.descricao
              : '-'
          }}
        </template>
        <template v-slot:[`item.pedidos_agendados.numero_nfe`]="{ item }">
          <div class="div-pedido-info">
            <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
              {{ pedido.numero_nfe }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.pedidos_agendados.quantidade`]="{ item }">
          <div class="div-pedido-info">
            <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
              {{ converteKg(pedido.quantidade_agendada) }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.triado_em`]="{ item }">
          {{ item.triado_em ? new Date(item.triado_em).toLocaleString() : '-' }}
        </template>
        <template v-slot:[`item.data_cota`]="{ item }">
          <div v-if="item.data_cota">
            <span v-if="cota_dia_todo(item.data_cota)">{{
              item.data_cota.data_inicio | iso2br
            }}</span>
            <span v-else-if="cota_mesmo_dia(item.data_cota)">
              {{ mostra_cota_mesmo_dia(item.data_cota) }}</span
            >
            <span v-else>
              {{ item.data_cota.data_inicio | toBrazilianDateTime }}
              -
              {{ item.data_cota.data_fim | toBrazilianDateTime }}
            </span>
          </div>
          <div v-else>Não definida</div>
        </template>
        <template v-slot:[`item.data_pedido`]="{ item }">
          <div v-if="item.data_pedido">
            <span v-if="cota_mesmo_dia(item.data_pedido)">
              {{ item.data_pedido.data_inicio | iso2br }}</span
            >
            <span v-else>
              {{ item.data_pedido.data_inicio | iso2br }}
              -
              {{ item.data_pedido.data_fim | iso2br }}
            </span>
          </div>
          <div v-else>Não definida</div>
        </template>
        <template v-slot:[`item.numero_pedido`]="{ item }">
          <p v-for="(pedido, index) in item.pedidos_agendados" :key="index">
            {{ pedido.pedido.numero_pedido }}
          </p>
        </template>
        <template v-slot:[`item.tempoEmPatio`]="{ item }">
          {{ getTempoEmPatio(item) }}
        </template>
        <template #no-data v-if="!canList">
          <span class="red--text"
            >Você não tem permissão para visualizar as informações desta
            tela</span
          >
        </template>
      </v-data-table>
      <download-excel
        name="em-patio.xls"
        :data="orderedEmPatio"
        :fields="fields_excel"
      >
        <v-btn color="info" class="w-100 mt-5" style="margin-bottom: 20px">
          <v-icon class="mr-2" left dark> mdi-download </v-icon>
          Baixar Planilha</v-btn
        >
      </download-excel>
    </v-card>

    <v-dialog v-model="showComprovanteLaudo">
      <v-card>
        <comprovante-laudo :itensLaudo="this.itensLaudo" />
      </v-card>
    </v-dialog>
  </BaseCard>
</template>

<script>
import ModalChamarCaminhoes from '../ModalChamarCaminhoes.vue'
import ComprovanteLaudo from '@/components/patio/NovoAgendamento/comprovanteLaudo/Componente.vue'
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import { iso2br, toBrazilianDateTime } from '@/mixins/convertion'

export default {
  name: 'EmPatio',
  components: {
    ModalChamarCaminhoes,
    ComprovanteLaudo,
  },
  mixins: [cota_dia_todo, cota_mesmo_dia, mostra_cota_mesmo_dia],
  filters: {
    iso2br,
    toBrazilianDateTime,
  },
  data,
  provide() {
    return {
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
    }
  },
  computed,
  methods,
  watch: {
    'emPatio.length': {
      handler() {
        this.$emit('update:totalEmPatio', String(this.emPatio.length))
      },
    },
  },
}
</script>
<style>
.menu {
  margin-top: 20px;
}
</style>
